<div *transloco="let t;">
    <div class="card">
        <div class="card-header">Accès</div>
        <div class="card-body">
            <div class="row">
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['users']" [class.disabled]="!user.hasPermission('user.admin')">
                    <i class="fa fa-users"></i> {{ t('administration.section.users') }}
                </a>
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-tags"></i> {{ t('administration.section.roles') }}
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">Administratif</div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-building"></i> {{ t('administration.section.entities') }}
                </div>
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-sitemap"></i> {{ t('administration.section.business_units') }}
                </div>
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['public-holidays']" [class.disabled]="!user.hasPermission('public-holiday.admin')">
                    <i class="fas fa-calendar-times"></i> {{ t('administration.section.public_holidays') }}
                </a>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">Financier</div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-money-check"></i> {{ t('administration.section.bank_accounts') }}
                </div>
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-percentage"></i> {{ t('administration.section.vat_codes') }}
                </div>
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-euro-sign"></i> {{ t('administration.section.currencies') }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 index-item">
                    <i class="fa fa-clock"></i> {{ t('administration.section.payment_conditions') }}
                </div>

            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">Ressources humaines</div>
        <div class="card-body">
            <div class="row">
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['employees']" [class.disabled]="!user.hasPermission('employee.admin')">
                    <i class="fas fa-user-tie"></i> {{ t('administration.section.employees') }}
                </a>
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['workers']" [class.disabled]="!user.hasPermission('worker.admin')">
                    <i class="fa fa-hard-hat"></i> {{ t('administration.section.workers') }}
                </a>
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['subcontractors']" [class.disabled]="!user.hasPermission('subcontractor.admin')">
                    <i class="icon-subcontractor"></i> {{ t('administration.section.subcontractors') }}
                </a>
            </div>
            <div class="row">
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['hr', 'config']" [class.disabled]="!user.hasPermission('employee.admin')">
                    <i class="fas fa-toolbox"></i> {{ t('administration.section.rh') }}
                </a>
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['work-extras']" [class.disabled]="!user.hasPermission('work-extra.admin')">
                    <i class="fas fa-coffee"></i> {{ t('administration.section.work_extra') }}
                </a>
            </div>
            <div class="row">
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['badges']" [class.disabled]="!user.hasPermission('badge.admin')">
                    <i class="fas fa-id-card"></i> {{ t('administration.section.badge') }}
                </a>
                <a class="col-sm-4 col-md-4 index-item" [routerLink]="['badge-readers']" [class.disabled]="!user.hasPermission('badge-reader.admin')">
                    <i class="fas fa-mobile-alt"></i> {{ t('administration.section.badge-reader') }}
                </a>
            </div>
        </div>
    </div>
</div>