<div [style.display]="state.value === 'inactive' ? 'none' : ''">
    <i class="toaster-icon icon-success"></i>
    <div class="toast-content">
        <div class="toast-title">{{ title }}</div>
        <div class="toast-message">
            <!---->
            <!---->
            <!---->
            <div class="ng-star-inserted">{{ message }}</div>
        </div>
    </div>
    <!---->
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>