import { Directive, SkipSelf, Self, Host, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

/**
 * A directive to automatically set the `ng-submitted` class on the `<form>` when it is submitted
 ```html
 <form [formGroup]="form" (ngSubmit)="onSubmit()" jdcFormSubmit="submitted">
	<!-- ... -->
 </form>
 ```
 ```ts
 submitted: boolean = false;
 //...
 onSubmit() {
     if (!this.submitted) {
         return;
	 }
	 // process submitting
 }
 ```
 */
@Directive({
	selector: '[jdcFormSubmit]'
})
export class JdcFormSubmitDirective {

	/**
	 * The input submit to get if the form is already submitted or not
	 */
	@Input() jdcFormSubmit: boolean;
	/**
	 * The output to notify when the state for the form (submitted or not) change.
	 */
	@Output() jdcFormSubmitChange = new EventEmitter<boolean>();

	/**
	 * Constructs the directive
	 * @param form
	 * @param el
	 */
	constructor(private el: ElementRef) {
	}

	/**
	 * Method called with the "submit" event is triggered on the form.
	 * Triggers the `ngSubmit` emitter to emit the "submit" event as its payload.
	 *
	 * @param $event The "submit" event object
	 */
	@HostListener('submit', ['$event'])
	onSubmit($event: Event): void {
		this.el.nativeElement.classList.add('ng-submitted');
		this.jdcFormSubmit = true,
		this.jdcFormSubmitChange.emit(true);
	}

	/**
	 * Method called when the "reset" event is triggered on the form.
	 */
	@HostListener('reset')
	onReset(): void {
		this.el.nativeElement.classList.remove('ng-submitted');
		this.jdcFormSubmit = false;
		this.jdcFormSubmitChange.emit(false);
	}
}
