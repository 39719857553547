import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor() {}

	set(key: string, value: any) {
		return localStorage.setItem(key, value);
	}

	has(key: string): Promise<boolean> {
		return of(localStorage.getItem(key)).pipe(map(value => {
			if (value) {
				return true;
			}
			return false;
		})).toPromise();
	}

	get(key: string, defaultValue: any = undefined): Promise<String> {
		return of(localStorage.getItem(key)).pipe(map(value => {
			if (value) {
				return value;
			}
			return defaultValue;
		})).toPromise();
	}

	remove(key: string) {
		return localStorage.removeItem(key);
	}
}
