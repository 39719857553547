import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

@Component({
	selector: 'app-toastr',
	templateUrl: './toastr.component.html',
	styleUrls: ['./toastr.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('inactive', style({ opacity: 0 })),
			transition(
				'inactive => active',
				animate(
					'300ms ease-out',
					style({ opacity: 1 })
				),
			),
			state(
				'active',
				style({
					opacity: 1
				}),
			),
			transition(
				'active => removed',
				animate(
					'300ms ease-out',
					style({ opacity: 0 })
				),
			),
		]),
	]
})
export class ToastrComponent extends Toast {

	constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
		super(toastrService, toastPackage);

	}

	action(event: Event) {
		event.stopPropagation();
		this.toastPackage.triggerAction();
		return false;
	}

}
