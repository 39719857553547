<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <ng-container *transloco="let t">
                                <div class="card-body">
                                    <form [formGroup]="form" [(jdcFormSubmit)]="submit" (ngSubmit)="savePassword()"
                                        novalidate>
                                        <h1>{{ t('password_reset.header') }}</h1>
                                        <p class="text-muted">{{ t('password_reset.code_help') }}</p>

                                        <!-- Trick so that the browser dose'nt feed the code -->
                                        <div style="overflow: hidden;">
                                            <input type="text" id="username"
                                                style="position: absolute; left: -1000px; top: -1000px;" name="username"
                                                value="">
                                            <input type="password" id="password"
                                                style="position: absolute; left: -1000px; top: -1000px;" name="password"
                                                value="">
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label for="code"
                                                    class="required">{{ t('password_reset.code_label') }}</label>
                                                <input type="texte" class="form-control col-sm-3"
                                                    [attr.placeholder]="t('password_reset.code_placeholder')"
                                                    formControlName="code" name="code" required>
                                                <div class="form-error" *ngIf="form.get('code').errors?.required"
                                                    @errorAnimation>
                                                    {{ t('password_reset.code_required_error') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label for="password"
                                                    class="required">{{ t('password_reset.new_password_label') }}</label>
                                                <input type="password" class="form-control"
                                                    [attr.placeholder]="t('password_reset.new_password_placeholder')"
                                                    formControlName="password" name="password" required>
                                                <password-strength-meter [password]="form.get('password').value" minPasswordLength="8" [enableFeedback]="false">
                                                </password-strength-meter>
                                                <div class="form-error" *ngIf="form.get('password').errors?.required"
                                                    @errorAnimation>
                                                    {{ t('password_reset.password_required_error') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label for="passwordConfirm"
                                                    class="required">{{ t('password_reset.new_password_confirm_label') }}</label>
                                                <input type="password" class="form-control"
                                                    [attr.placeholder]="t('password_reset.new_password_confirm_placeholder')"
                                                    formControlName="passwordConfirm" name="passwordConfirm" required>
                                                <div class="form-error"
                                                    *ngIf="form.get('passwordConfirm').errors?.required"
                                                    @errorAnimation>
                                                    {{ t('password_reset.password_confirm_required_error') }}
                                                </div>
                                                <div class="form-error"
                                                    *ngIf="form.get('passwordConfirm').errors?.matchValues"
                                                    @errorAnimation>
                                                    {{ t('password_reset.password_confirm_match_error') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div role="alert" class="error alert alert-danger" @errorAnimation *ngIf="error"
                                            [ngSwitch]="error">
                                            <div *ngSwitchCase="400">{{ t('password_reset.bad_code_error') }}</div>
                                            <div *ngSwitchDefault>{{ t('password_reset.error') }}</div>
                                        </div>
                                        <div role="alert" class="success alert alert-success" @errorAnimation *ngIf="success">
                                            {{ t('password_reset.success', { seconds: delay }) }}
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button type="submit" class="btn btn-primary px-4"
                                                    [ladda]="isLoading">{{ t('password_reset.save_password_button') }}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </main>
</div>