<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <ng-container *transloco="let t">
                                <div class="card-body">
                                    <form [formGroup]="form" (ngSubmit)="sendResetEmail()">
                                        <h1>{{ t('password_reset.header') }}</h1>
                                        <p class="text-muted">{{ t('password_reset.help') }}</p>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="far fa-envelope"></i></span>
                                            </div>
                                            <input type="email" class="form-control"
                                                [attr.placeholder]="t('password_reset.email_placeholder')"
                                                autocomplete="email" formControlName="email" [email]="true" required>
                                        </div>
                                        <div role="alert" class="error alert alert-danger" @errorAnimation *ngIf="error" [ngSwitch]="error">
                                            <div *ngSwitchCase="404">{{ t('password_reset.not_404_error') }}</div>
                                            <div *ngSwitchDefault>{{ t('password_reset.error') }}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button type="submit" class="btn btn-primary px-4"
                                                    [disabled]="!form.valid" [ladda]="isLoading">{{ t('password_reset.send_button') }}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </main>
</div>