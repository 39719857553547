import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetValidateComponent } from './pages/password-reset-validate/password-reset-validate.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdministrationComponent } from './pages/administration/administration.component';
import { LogoutComponent } from './logout/logout.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';

export const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'login_page_title',
			animation: 'Login'
		}
	},
	{
		path: 'logout',
		component: LogoutComponent,
		data: {
			title: 'login_page_title',
			animation: 'Login'
		}
	},
	{
		path: 'password-reset',
		component: PasswordResetComponent,
		data: {
			title: 'Reset password',
			animation: 'PasswordReset'
		}
	},
	{
		path: 'password-reset-validate/:email',
		component: PasswordResetValidateComponent,
		data: {
			animation: 'PasswordResetValidate'
		}
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
		data: {
			animation: 'Dashboard'
		}
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
			animation: 'Page404'
		}
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
			animation: 'Page500'
		}
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		canActivate: [
			AuthGuardService
		],
		children: [{
			path: 'dashboard',
			component: DashboardComponent,
			data: {
				title: 'dashboard.page_title',
				animation: 'Dashboard'
			}
		},
		{
			path: 'changelog',
			component: ChangelogComponent,
			data: {
				title: 'changelog',
				animation: 'Changelog'
			}
		},
		{
			path: 'users',
			loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
		},
		{
			path: 'sites',
			loadChildren: () => import('./modules/site/site.module').then(m => m.SiteModule)
		},
		{
			path: 'customers',
			loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
		},
		{
			path: 'quotations',
			loadChildren: () => import('./modules/quotation/quotation.module').then(m => m.QuotationModule)
		},
		{
			path: 'projects',
			loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
		},
		{
			path: 'worksheets',
			loadChildren: () => import('./modules/worksheet/worksheet.module').then(m => m.WorksheetModule)
		},
		{
			path: 'timesheets',
			loadChildren: () => import('./modules/timesheet/timesheet.module').then(m => m.TimesheetModule)
		},
		{
			path: 'leaves',
			loadChildren: () => import('./modules/leave/leave.module').then(m => m.LeaveModule)
		},
		/*{
			path: 'timesheets/employee',
			loadChildren: () => import('./modules/employee-timesheet/employee-timesheet.module').then(m => m.EmployeeTimesheetModule)
		},*/
		{
			path: 'support',
			loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule)
		},
		{
			path: 'reports',
			loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
		},
		{
			path: 'administration',
			pathMatch: 'full',
			component: AdministrationComponent,
			data: {
				title: 'administration.breadcrumb'
			}
		},
		{
			path: 'news',
			loadChildren: () => import('./modules/news-article/news-article.module').then(m => m.NewsArticleModule)
		},
		{
			path: 'administration',
			data: {
				title: 'administration.breadcrumb'
			},
			children: [
				{
					path: 'users',
					data: {
						title: 'user.list.breadcrumb'
					},
					loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
				},
				{
					path: 'subcontractors',
					loadChildren: () => import('./modules/subcontractor/subcontractor.module').then(m => m.SubcontractorModule)
				},
				{
					path: 'public-holidays',
					loadChildren: () => import('./modules/public-holiday/public-holiday.module').then(m => m.PublicHolidayModule)
				},
				{
					path: 'work-extras',
					loadChildren: () => import('./modules/work-extra/work-extra.module').then(m => m.WorkExtraModule)
				},
				{
					path: 'employees',
					loadChildren: () => import('./modules/employee/employee.module').then(m => m.EmployeeModule)
				},
				{
					path: 'workers',
					loadChildren: () => import('./modules/worker/worker.module').then(m => m.WorkerModule)
				},
				{
					path: 'hr/config',
					loadChildren: () => import('./modules/hr-config/hr-config.module').then(m => m.HrConfigModule)
				},
				{
					path: 'badges',
					loadChildren: () => import('./modules/badge/badge.module').then(m => m.BadgeModule)
				},
				{
					path: 'badge-readers',
					loadChildren: () => import('./modules/badge-reader/badge-reader.module').then(m => m.BadgeReaderModule)
				},
			]
		}]
	},


	{
		path: '**',
		component: P404Component,
		data: { animation: 'Default' }
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
