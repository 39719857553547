export interface PhoneMask {
	label: string;
	countryCode: number;
	prefix: string;
	landline: string;
	mobile: string;
}

export let masks: PhoneMask[] = [{
	label: 'BE',
	countryCode: 32,
	prefix: '+32 (0)',
	landline: '00 00 00 00',
	mobile: '000 00 00 00'
}, {
	label: 'FR',
	countryCode: 33,
	prefix: '+33 ',
	landline: '0 00 00 00 00',
	mobile: '0 00 00 00 00'
}, {
	label: 'NL',
	countryCode: 31,
	prefix: '+31 (0)',
	landline: '00 0000000',
	mobile: '00 00000000'
}, {
	label: 'Autre',
	countryCode: null,
	prefix: '',
	landline: null,
	mobile: null
}];
